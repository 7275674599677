import React, {memo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as Camera} from "../../assets/profile/icon_camera.svg";

import ButtonWrapper from "../custom/button-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import PropTypes from "prop-types";
import useConfig from "../qnect-ui/qnect-config";
import {connect} from "react-redux";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
     _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {_getUser, _updateMyProfileDetails} from "../../store/user/service";
import styled from "styled-components";
import {lgDown, smDown} from "../../ui";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import {_validateForm} from "../../utils/utility";
import {StyledModalDiv} from "../login-style";
import {ReactComponent as BlueDone} from "../../assets/BlueDone.svg";
import {StyledQnectButton} from "../portfolio/portfolio-style";
import ProfileBaseUpdateForm from "./profile-base-update-form";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        boxShadow: "0 0 60px 0 rgba(0, 0, 0, 0.06)",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            margin: "65px auto 0",
            padding: "14px 20px 14px",
            width: "343px",
            minHeight: "200px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            margin: "135px auto 0",
            padding: "40px 135px 40px",
            width: "720px",
            minHeight: "250px"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "-105px auto 0",
            padding: "40px 60px 40px",
            width: "600px",
            minHeight: "250px"
        }
    },
    avatarWrapper: {
        display: "flex",
        // position: "absolute",
        // borderRadius: "50%",
        // backgroundColor: "#ffffff",
        // boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
        // justifyContent: "center",
        // alignItems: "center",
        // [theme.breakpoints.down("sm")]: {
        //   top: "-35px",
        //   left: "calc(50% - 45px)",
        //   width: "90px",
        //   height: "90px"
        // },
        // [theme.breakpoints.up("sm")]: {
        //   top: "-65px",
        //   left: "calc(50% - 65px)",
        //   width: "130px",
        //   height: "130px"
        // }
    },
    avatar: {
        display: "block",
        borderRadius: "50%",
        boxShadow: "2px 1px 5px 0 rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            height: "80px",
            width: "80px"
        },
        [theme.breakpoints.up("sm")]: {
            height: "116px",
            width: "116px"
        }
    },
    cameraWrapper: {
        position: "absolute",
        right: "0",
        height: "38px",
        width: "38px",
        border: "2px solid #ffffff",
        borderRadius: "50%",
        backgroundColor: "#003da5",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            bottom: "0px"
        },
        [theme.breakpoints.up("sm")]: {
            bottom: "10px"
        }
    },
    name: {
        color: "#0a1f44",
        fontFamily: "Stag",
        fontWeight: "500",
        letterSpacing: "1px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "33px",
            fontSize: "20px",
            lineHeight: "28px"
        },
        [theme.breakpoints.up("sm")]: {
            marginBottom: "40px",
            fontSize: "32px",
            lineHeight: "40px"
        }
    },
    row: {
        display: "inline-flex",
        alignItems: "left",
        color: "#0a1f44",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "20px",
        marginBottom: "20px",
        "& > span:first-child": {
            [theme.breakpoints.down("sm")]: {
                width: "120px"
            },
            [theme.breakpoints.up("sm")]: {
                width: "200px"
            }
        }
    },
    button: {
        display: "block",
        margin: "40px auto 0",
        [theme.breakpoints.down("sm")]: {
            width: "150px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "160px"
        }
    },
    editButton: {
        "& > svg": {
            right: "-527px",
            [theme.breakpoints.down("sm")]: {
                right: "-276px",
                top: "6px"
            },
            [theme.breakpoints.up("lg")]: {
                right: "-461px",
            },
            position: "relative",
            cursor: "pointer",
            width: "25px",
            height: "25px",

        },
        position: "relative"
    }
}));

const styledButtonWrapperSmDownStyling = () => {
    return smDown`width: 100%`;
};

const StyledButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  justify-content: space-between;
  width: 390px;
  margin: 0 auto;
  ${styledButtonWrapperSmDownStyling}
`;


const styledTextFieldWrapperLgDownStyling = () => {
    return lgDown`
      font-size: 16px;
      line-height: 24px;
      top: -30px;
    `;
};

const StyledTextFieldWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  .MuiInputLabel-formControl {
    top: -35px;
    color: #0a1f44;
    font-family: Inter;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
  }
  .MuiInputLabel-filled,
  .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
  }
  
    .SelectField .MuiInputLabel-root {
        transform: none !important;
        padding: 0;
    }
  
    .SelectField .MuiSelect-root.MuiInput-input{
        padding-top: 7px;
    }
   
  .MuiFilledInput-underline:before,
  .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
  .form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #0a1f44 !important;
  }
  & .MuiInputLabel-formControl{
    ${styledTextFieldWrapperLgDownStyling}
  }      
`;

const styledSubTextLgDownStyling = () => {
    return lgDown`
        font-size: 14px;
        line-height: 20px;
        padding: 4px 0 0 0;
        width: 100%;
    `;
};

const SubText = styled.div`
  color: #003da5;
  font-family: "Inter";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 0 0 4px;
  margin-bottom: 52px;
  ${styledSubTextLgDownStyling}
  white-space: pre-wrap;
  @media (max-width:767.95px) {
    white-space:  normal;
  }
`;

const styledSubTextTopLgDownStyling = () => {
    return lgDown`
        font-size: 14px;
        line-height: 20px;
        padding: 4px 0 0 0;
        width: 100%;
    `;
};

const SubTextTop = styled.div`
  color: #003da5;
  font-family: "Inter";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 0 0 4px;
  margin-bottom: 20px;
  margin-top: 20px;
  ${styledSubTextTopLgDownStyling}
  white-space: pre-wrap;
  @media (max-width:767.95px) {
    white-space:  normal;
  }
`;

const ProfileBase = (props) => {
    const classes = useStyles();
    const cfg = useConfig(props.lang);
    const formId = "updateProfile";

    const checkIfDistrictValid = (val) => {
        let districtList = ['HK','KLN','NT','Outlying Islands'];
        if (!districtList.includes(val)) {
            return null;
        } else {
            return val;
        }
    }

    const [showUpdateDetails, setShowUpdateDetails] = useState(false);

    const [email, setEmail] = useState(props.user.email);
    const [phoneNumber, setPhoneNumber] = useState(props.user.phoneNumber);
    //address fields
    const [unit, setUnit] = useState(props.user.unit);
    const [building, setBuilding] = useState(props.user.building);
    const [street, setStreet] = useState(props.user.street);
    const [district, setDistrict] = useState(checkIfDistrictValid(props.user.district));

    //error fields
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [unitErrorMessage, setUnitErrorMessage] = useState('');
    const [buildingErrorMessage, setBuildingErrorMessage] = useState('');
    const [streetErrorMessage, setStreetErrorMessage] = useState('');
    const [districtErrorMessage, setDistrictErrorMessage] = useState('');

    const toggleShowUpdateDetailsFields = () => {
        setShowUpdateDetails(!showUpdateDetails);
    }

    const cancelUpdate = () => {
        setEmailErrorMessage('');
        setPhoneErrorMessage('');
        setUnitErrorMessage('');
        setBuildingErrorMessage('');
        setStreetErrorMessage('');
        setDistrictErrorMessage('');

        // return to original values
        setEmail(props.user.email);
        setPhoneNumber(props.user.phoneNumber);
        setUnit(props.user.unit);
        setBuilding(props.user.building);
        setStreet(props.user.street);
        setDistrict(props.user.district);
        toggleShowUpdateDetailsFields();
    }



    const callUpdate = () => {
        if (validateFields()) {
            let emailOrMobileUpdated = 'N';
            let hasAddressUpdates = 'N';
            if (email != props.user.email || phoneNumber != props.user.phoneNumber) {
                emailOrMobileUpdated = 'Y';
            }

            if (unit != props.user.unit || building != props.user.building ||
                street != props.user.street || district != props.user.district) {
                hasAddressUpdates = 'Y';
            }

            let updateMyProfileJson = {
                email: email,
                phoneNumber: phoneNumber,
                hasEmailOrMobileUpdates: emailOrMobileUpdated,
                unit: unit,
                building: building,
                street: street,
                district: district,
                hasAddressUpdates: hasAddressUpdates,
                isHGI: props.user.isHGI
            }

            if (emailOrMobileUpdated === 'Y' || hasAddressUpdates === 'Y') {
                props.showSpinner();
                props.updateMyProfileDetails(updateMyProfileJson).then(() => {
                    props.hideSpinner();
                    props.showModal({
                        flat: true,
                        content: <StyledModalDiv>
                            <div className="iconDiv">
                                <BlueDone/>
                            </div>
                            <div className="title" style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                                { cfg('labels.myProfile.success') }
                            </div>
                            <div className="content">{ cfg('labels.myProfile.updateSuccess') }</div>
                            <div className="buttonDiv">
                                <StyledQnectButton
                                    field="continue"
                                    onClick={() => {
                                        props.hideModal();
                                        toggleShowUpdateDetailsFields();
                                    }}></StyledQnectButton>
                            </div>
                        </StyledModalDiv>
                    });
                }, (error) => {
                    props.hideSpinner();
                    if (error?.response?.status == 500) {
                        props.showError(['Internal Server Error']);
                    } else {
                        setEmailErrorMessage(cfg(error))
                    }
                });
            }
        }
    }

    const validateFields = () => {
        let validateFormFields = _validateForm(formId);

        if (!validateFormFields) {
            return false;
        }

        let validatePhone = validatePhoneNumberFormat(phoneNumber);
        let validateAddress = checkIfAddressFieldsValid();
        if (!validatePhone || !validateAddress) {
            return false;
        }

        return true;
    }

    const validatePhoneNumberFormat = (number) => {
        if (props.country === 'HKG') {
            let regex = /^[5679](\d{7})$/g;
            if (number.match(regex)){
                setPhoneErrorMessage('');
                return true;
            } else {
                setPhoneErrorMessage(cfg('registration.errorMessages.invalidPhoneNumberFormat'));
                return false;
            }
        } else {
            setPhoneErrorMessage('');
            return true;
        }
    }

    const validateAddress = (address) => {
        let regex = /^[+_@#.'/:()&#%0-9a-zA-Z\s,-]+$/g;
        if (address.match(regex)){
            return true;
        } else {
            return false;
        }
    }

    const checkIfAddressFieldsValid = () => {
        setUnitErrorMessage('');
        setBuildingErrorMessage('');
        setStreetErrorMessage('');
        setDistrictErrorMessage('');
        let returnValue = true;
        if (unit && !validateAddress(unit)) {
            setUnitErrorMessage(cfg('labels.personalDetails.addressFieldInvalidFormat'));
            returnValue = false;
        }
        if (building && !validateAddress(building)) {
            setBuildingErrorMessage(cfg('labels.personalDetails.addressFieldInvalidFormat'));
            returnValue = false;
        }

        if (street && !validateAddress(street)) {
            setStreetErrorMessage(cfg('labels.personalDetails.addressFieldInvalidFormat'));
            returnValue = false;
        }

        if (district && !validateAddress(district)) {
            setDistrictErrorMessage(cfg('labels.personalDetails.addressFieldInvalidFormat'));
            returnValue = false;
        }

        return returnValue;
    }

    const checkIfFieldHasValue = (fieldValue) => {
        if (fieldValue) {
            return fieldValue
        }

        return cfg('profilePage.notAvailable');
    }

    const changeFieldToZh = (field) => {
        if (props.lang === 'zh') {
            return field + '_zh';
        } else {
            return field;
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.avatarWrapper}>
                {/*<img className={classes.avatar} src={imgSrc} alt={cfg("profilePage.avatar")} />*/}
            </div>

            <div className={classes.name}>{props.user.userName}</div>
            {
                showUpdateDetails ? <>
                    {props.country !== 'HKG' ? 
                        <>
                            <StyledTextFieldWrapper>
                                <QnectTextField 
                                    field="registrationPhoneNumber"
                                    value={phoneNumber}
                                    onBlur={(e) => {
                                        setPhoneNumber(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                        setPhoneErrorMessage('');
                                    }}
                                    maxLength={60}
                                    errorMessage={phoneErrorMessage}
                                    formId={formId}
                                />
                            </StyledTextFieldWrapper>
                            <StyledTextFieldWrapper>
                                <QnectTextField 
                                    field="registrationEmailAddress"
                                    value={email}
                                    onBlur={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailErrorMessage('');
                                    }}
                                    maxLength={60}
                                    errorMessage={emailErrorMessage}
                                    formId={formId}
                                />
                            </StyledTextFieldWrapper>
                        </> :
                        <>
                            <StyledTextFieldWrapper>
                                <QnectTextField 
                                    field="registrationEmailAddress"
                                    value={email}
                                    onBlur={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailErrorMessage('');
                                    }}
                                    maxLength={60}
                                    errorMessage={emailErrorMessage}
                                    formId={formId}
                                />
                            </StyledTextFieldWrapper>
                            <StyledTextFieldWrapper>
                                <QnectTextField 
                                    field="registrationPhoneNumber"
                                    value={phoneNumber}
                                    onBlur={(e) => {
                                        setPhoneNumber(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                        setPhoneErrorMessage('');
                                    }}
                                    maxLength={60}
                                    errorMessage={phoneErrorMessage}
                                    formId={formId}
                                />
                            </StyledTextFieldWrapper>
                        </>
                    }
                    {props.country !== 'MYS' && (
                        <>
                            <StyledTextFieldWrapper>
                                <QnectTextField field="registrationUnit"
                                    formId={formId}
                                    value={unit}
                                    onBlur={(e) => {
                                        setUnit(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setUnit(e.target.value);
                                        setUnitErrorMessage('');
                                    }}
                                    maxLength={30}
                                    errorMessage={unitErrorMessage}
                                />
                            </StyledTextFieldWrapper>
                            <StyledTextFieldWrapper>
                                <QnectTextField field="registrationBuilding"
                                    formId={formId}
                                    value={building}
                                    onBlur={(e) => {
                                        setBuilding(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setBuilding(e.target.value);
                                        setBuildingErrorMessage('');
                                    }}
                                    maxLength={30}
                                    errorMessage={buildingErrorMessage}
                                />
                            </StyledTextFieldWrapper>
                            <StyledTextFieldWrapper>
                                <QnectTextField field="registrationStreet"
                                    formId={formId}
                                    value={street}
                                    onBlur={(e) => {
                                        setStreet(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setStreet(e.target.value);
                                        setStreetErrorMessage('');
                                    }}
                                    maxLength={30}
                                    errorMessage={streetErrorMessage}
                                />
                            </StyledTextFieldWrapper>
                            <StyledTextFieldWrapper>
                                <QnectSelectField field={changeFieldToZh("registrationDistrict")}
                                    formId={formId}
                                    value={district}
                                    onBlur={(e) => {
                                        setDistrict(e.target.value);
                                    }}
                                    onChange={(e) => {
                                        setDistrict(e.target.value);
                                        setDistrictErrorMessage('');
                                    }}
                                    errorMessage={districtErrorMessage}
                                />
                            </StyledTextFieldWrapper>
                        </>
                    )}
                    <SubTextTop>{cfg('labels.personalDetails.messageAddressTop')}</SubTextTop>
                    <SubText>{props.country !== 'HKG' ? cfg('labels.personalDetails.messageAddress_my') : cfg('labels.personalDetails.messageAddress')}</SubText>
                    <StyledButtonWrapper>
                        <QnectButton color="secondary" field="cancel" onClick={cancelUpdate}/>
                        <QnectButton color="primary" field="update" onClick={callUpdate}/>
                    </StyledButtonWrapper>
                </> : <>

                    {props.country !== 'HKG' && <>
                        <div className={classes.row}>
                            <span>{cfg("profilePage.mobileNumber")}</span>
                            <span>
                          {props.user.phoneNumber &&
                          `${props.user.phoneNumber}`}
                                {!props.user.phoneNumber && cfg('profilePage.notAvailable')}
                        </span>
                        </div>
                        <div className={classes.row}>
                            <span>{cfg("profilePage.emailAddress")}</span>
                            <span>
                            {checkIfFieldHasValue(props.user.email)}
                        </span>
                        </div>
                    </>}

                    {props.country === 'HKG' && <>
                        <ProfileBaseUpdateForm toggleUpdateForm={toggleShowUpdateDetailsFields}/>
                    </>}
                </>
            }

        </div>
    );
};

ProfileBase.propTypes = {
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    countryCode: PropTypes.string,
    phoneNum: PropTypes.string,
    email: PropTypes.string,
    onLogout: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: _getUser(state),
        lang: _getLang(state),
        country: _getCountry(state)
    }
}

const mapDispatchToProps = {
    updateMyProfileDetails: _updateMyProfileDetails,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(ProfileBase));
