import React, {memo} from "react";
import styled from "styled-components";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../dialog-title";
import QnectButton from "../../qnect-ui/qnect-button";
import ButtonWrapper from "../button-wrapper";
import { smDown } from "../../../ui/common/breakpoints";
import PropTypes from "prop-types";
import {_getLang} from "../../../store/system/service";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import useConfig from "../../qnect-ui/qnect-config";

const StyledMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 470px;
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    width: auto;
  `}
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  margin: 0;
`;

const StyledCancelQnectButton = styled(StyledQnectButton)`
  box-shadow: none;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  justify-content: space-between;
  width: 390px;
  margin: 0 auto;
  ${smDown`
    width: 100%;
  `}
`;

const StyledDialog = withStyles((theme) => ({
    paper: {
        padding: "14px 76px 40px",
        [theme.breakpoints.between("sm", "md")]: {
            padding: "14px 28px 32px",
        },
    },
    paperWidthSm: {
        boxSizing: "border-box",
        maxWidth: "none",
    },
}))(Dialog);

const StyledDialogActions = withStyles((theme) => ({
    root: {
        margin: "44px 52.5px 0",
        fontFamily: "Inter",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            margin: "40px 0 0",
        },
    },
    button: {
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("sm")]: {
            marginLeft: "22px"
        }
    }
}))(DialogActions);

const FileClaimDialog = (props) => {
    const cfg = useConfig(props.lang);

    return (
        <div>
            <StyledDialog open={props.open}>
                <DialogTitle title={cfg('labels.fileAClaim.title')} closable={false} lang={props.lang}/>
                <DialogContent>
                    <StyledMessage>
                        {cfg('labels.fileAClaim.message')}
                    </StyledMessage>
                </DialogContent>
                <StyledDialogActions>
                    <ButtonWrapper color="secondary">
                        <QnectButton color="secondary" field="cancel" onClick={props.onClose()}>
                        </QnectButton>
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <QnectButton className={StyledDialogActions.button} field="confirm" onClick={() => props.onConfirm()}>
                        </QnectButton>
                    </ButtonWrapper>
                </StyledDialogActions>
            </StyledDialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
}

export default memo(connect(mapStateToProps)(FileClaimDialog));
