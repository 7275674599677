import {POLICY_LIST_RESET, POLICY_LIST_STORE, RESET_USER, SET_USER} from "./types";
import {updateObject} from "../../utils/utility";

const INITIAL_STATE = {
    userId: '',
    clientId: '',
    userName: '',
    clientType: '',
    dob: '',
    hkId: '',
    passport: '',
    company: '',
    brNum: '',
    email: '',
    emailVerified: '',
    emailVerifiedDate: '',
    countryCode: '',
    phoneNumber: '',
    agentNumber: '',
    agentName: '',
    country: '',
    profileImage: '',
    profileImageName: '',
    skipFlash: '',
    status: '',
    createdDate: '',
    createdBy: '',
    lastLoginDate: '',
    lastResetPasswordDate: '',
    lastResetPasswordCount: '',
    lastUpdatedDate: '',
    policyPortfolio: [],
    policyNumber: '',
    idNumber: '',
    policyHolder: '',
    isHGI: '',
    unit: '',
    building: '',
    street: '',
    district: '',
};

const updateUserState = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

const updatePolicyListState = (state, action) => {
    return updateObject(
      state,
      { policyPortfolio: action.data }
    )

}

const resetPolicyListState = (state, action) => {
    return updateObject(
      state,
      { policyPortfolio: [] }
    )

}

const resetState = (state) => {
    let obj = {...INITIAL_STATE};
    return updateObject(
      state,
      obj
    )
}

export const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER:
            return updateUserState(state, action);
        case POLICY_LIST_STORE:
            return updatePolicyListState(state, action);
        case POLICY_LIST_RESET:
            return resetPolicyListState(state, action);
        case RESET_USER:
            return resetState(state);
        default:
            return state;
    }
}


