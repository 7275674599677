import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {FormControl, FormHelperText, Input, InputLabel, Select} from '@material-ui/core';

import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import IconChevronDown from '../icons/legacy/chevron-down';

const FormControlStyled = styled(FormControl)`
    &.MuiFormControl-root {
        background-color: ${property('theme.color.white')};
        width: 100%;

        .MuiFormLabel-root {
            padding: 0 0 0 12px;
            color: ${property('theme.color.tertiary90')};
            font-family: ${property('theme.typography.text.fontFamily')};
        }

        .MuiInputLabel-shrink {
            transform: translate(3px, 12px) scale(0.75);
        }

        .Mui-focused {
            color: ${property('theme.color.primary')};
        }
        label.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
            z-index: 1;
        }
        .MuiInputBase-root.Mui-disabled {
            color: #000 !important;
            cursor: default;
            background-color: #C9CED6!important;
            z-index: 0;
        }

        .MuiInputBase-root {
            margin-top: 0;
            height: 63px;
            font-family: ${property('theme.typography.text.fontFamily')};
            border: 1px solid #C9CED6;
            border-radius: 12px;
        }

        .MuiInput-underline {
            border: ${property('theme.color.tertiary90')};
        }

        .MuiInput-underline::after {
            border-bottom-color: ${property('theme.color.secondary60')};
        }
        .MuiInput-underline.Mui-error::after {
            border-bottom-color: #A50000;
        }

        .MuiSelect-root {
            background-color: transparent;
            padding: 27px 16px 10px 12px;
            text-align: left;
            color: ${property('theme.color.black')};
        }

        .MuiInputBase-root.Mui-focused {
            border: 1px solid #1547b1;
          }
          
        .MuiInputBase-root.Mui-error, .MuiInputBase-root.Mui-focused.Mui-error {
            border: 1px solid  ${property('theme.color.error')};
        }
       
    }
`;

const StyledIconChevronDown = styled(IconChevronDown)`
    margin-right: 12px;
`;

const StyledFormHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-top: 0;
        padding: 0px 0 15px 13px;
        color: ${property('theme.color.tertiary90')};
        font-family: ${property('theme.typography.text.fontFamily')};
    }

    &.MuiFormHelperText-root.Mui-error {
        color: ${property('theme.color.error')};
        margin-top: 0;
        padding: 0 0 15px;
        color: ${property('theme.color.error')};
        font-family: ${property("theme.typography.text.fontFamily")};
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        margin-left: 0;
        margin-right: 0;
        display: contents !important;
        height: 0px;
    }
`;

const IconComponent = (props) => (<StyledIconChevronDown color="black" size="24px" {...props} />);

const DropDown = React.forwardRef((
    {
        className,
        name,
        label,
        isEmptyLabel = false,
        value: defaultValue,
        onChange,
        onBlur,
        isDisabled,
        isError,
        helperText,
        isRequired,
        children,
        ...rest
    },
    ref
) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);

        if (typeof (onChange) === 'function') {
            onChange(event.target.value);
        }
    };

    useEffect(() => {
        setSelectedValue(defaultValue || '');
    }, [defaultValue])

    return (
        <div className={classnames('DropDown', className)}>
            <FormControlStyled
                disabled={isDisabled}
                error={isError}
                required={isRequired}
            >
                {!isEmptyLabel && (
                    <InputLabel htmlFor={name}>
                        {label}
                    </InputLabel>
                )}

                <Select
                    {...rest}
                    ref={ref}
                    input={<Input name={name} id={name} />}
                    value={selectedValue}
                    onChange={handleChange}
                    displayEmpty={isEmptyLabel}
                    IconComponent={IconComponent}
                    onBlur={onBlur}
                    disableUnderline
                >
                    {children}
                </Select>
            </FormControlStyled>

            {!!helperText && (
                <StyledFormHelperText error={isError}>
                    {helperText}
                </StyledFormHelperText>
            )}
        </div>
    );
});

DropDown.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    isEmptyLabel: propTypes.bool,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    value: propTypes.string,
    isDisabled: propTypes.bool,
    isError: propTypes.bool,
    helperText: propTypes.string,
    isRequired: propTypes.bool,
    children: propTypes.node,
};

DropDown.displayName = 'DropDown';

export default DropDown;
