import React, {useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"
import '../../assets/styles/payment.css';
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import Visa from '../../assets/Visa_logo.svg';
import Mastercard from '../../assets/Mastercard_logo.svg';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import BalloonTip from "../ui/balloon-tip/balloon-tip";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang, _getPolicyToken,
    _getPortfolioToken,
    _hideSpinner,
    _setLang,
    _showError,
    _showSpinner
} from "../../store/system/service";
import {
    _adhocGenerateQrCode,
    _getPolicyPayment,
    _issueAndReceiptPolicy, _issueFailed,
    _renewInitiatePayment,
    _storePolicyPayment
} from "../../store/policy-payment/service";
import {
    _formatPaymentAmount,
    _getPolPaymentCurrency,
    _isNotDecimalChar,
    _sendLog,
    _validateForm,
    getProductName
} from "../../utils/utility";
import QnectError from "../qnect-ui/qnect-erorr";
import {makeStyles} from "@material-ui/core/styles";
import Header from "../custom/header";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import {_resetPolicy} from "../../store/policy/service";
import * as TagManager from "react-gtm-module";
import {rgba} from "polished";
import CreditCardIcon from "../../assets/icon_creditcard.svg";
import PayNowIcon from "../../assets/icon_paynow.svg";
import QnectRadioBox from "../qnect-ui/qnect-radio-box";
import PayNowImg from "../../assets/logo_paynow.png";
import QrCode from "../qr-code-payment/qr-code";
import {OPTIN, OPTOUT} from "../auto-renewal/auto-renewal-constants";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        cvv_input: props => ({
            "& .BalloonTip": props => ({
                left: props.lang === "en" ? "42px" : "58px"
            })
        })
    }
}));

const BackWrapper = styled.div`
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const BackIcon = styled(Back)`
  margin-right: 8px;
`;

const TopWrapper = styled.div`
    position: relative;
    height: 100%;
    margin: 0 166px 0 166px;
    width: 100%;
    max-width: 1110px;
`;

const Overlay = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background: ${({theme}) => rgba(theme.color.backdrop, 0.8)};
    ${({show}) => {
        if(show) {
            return "width: unset; height: unset"
        } else {
            return "width: 500px; height: 0px"
        }
    }}
`;

const RenewalDetail = (props) => {
    const formId = 'renewalDetail';
    const cfg = useConfig(props.lang);

    const [lang, setLang] = useState( props.lang);
    const [paymentSignature, setPaymentSignature] = useState(null);
    const formRef = useRef(null);
    const paymentGatewayIframeRef = useRef(null);

    const [productName, setProductName] = useState(null);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [totalAmount] = useState(props.policyPayment.amount);
    const [errorMessage] = useState(props.policyPayment.errorMessage);
    const [emailAddress, setEmailAddress] = useState (props.policyPayment.email);
    const [cardHolderName, setCardHolderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [showPaymentGatewayIframeModal, setShowPaymentGatewayIframeModal] = useState(false);
    const [otpMonitorActive, setOtpMonitorActive] = useState(false);
    const [otpMonitorTimer, setOtpMonitorTimer] = useState(0);
    const [otpMonitorIntervalId, setOtpMonitorIntervalId] = useState(null);
    const [paymentIframeContentLength, setPaymentIframeContentLength] = useState(0);
    const [paymentOption, setPaymentOption] = useState(props.policyPayment.paymentOption );
    const [autoRenewalStatus] = useState(props.policyPayment.autoRenewalStatus );
    const [eligibleForAutoRenewal] = useState (props.policyPayment.eligibleForAutoRenewal);

    const classes = useStyles(props);

    useEffect(() => {
        setProductName(getProductName(cfg, props.policyPayment.country, props.policyPayment.catalogCode));
    })

    const originalConsoleError = console.error;
    console.error = function () {
        const errorArguments = Array.from(arguments);
        const errorMessage = errorArguments.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(" ");
        _sendLog("renewal-detail.js: " + errorMessage, "error")
        originalConsoleError.apply(console, arguments);
    }

    useEffect(() => {
        if (errorMessage === 'The policy has already been paid. Payment is not required.') {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'vpageview',
                    pageStep: 'Paid - Renewal Detail - Payment - ' + productName,
                    vPath: '/renew/renewal-detail',
                    vProduct: productName,
                    vPolicyNo: policyNumber,
                    vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                    vPaymentAmount: totalAmount,
                    vCountry: props.policyPayment.country
                }
            });
        } else {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'vpageview',
                    pageStep: 'Renewal Detail - Payment - ' + productName,
                    vPath: '/renew/renewal-detail',
                    vProduct: productName,
                    vPolicyNo: policyNumber,
                    vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                    vPaymentAmount: totalAmount,
                    vCountry: props.policyPayment.country,
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: 1
                            }
                        }
                    }
                }
            });
        }

		if (props.policyPayment.country === 'HKG' || props.policyPayment.country === 'MYS') {
            setPaymentOption('C');
        }
    }, []);

    useEffect(() => {
        window.paymentSuccess = paymentSuccess.bind(this);
        window.paymentError = paymentError.bind(this);
    }, [props.policyPayment]);

    useEffect(() => {
        if (otpMonitorTimer > 0) {
            monitorPaymentGatewayResponse();
        }
    }, [otpMonitorTimer]);

    useEffect(() => {
        if (otpMonitorActive && !otpMonitorIntervalId) {
            setOtpMonitorIntervalId(setInterval(() => {
                setOtpMonitorTimer((otpMonitorTimer) => otpMonitorTimer + 1);
            }, 1000));
        }
    }, [otpMonitorActive]);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as '+newLang);
    }

    const onChangeCardNumberFormatHandler = (event) => {
        let cardNoRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{4}$", "g");
        let newCardNo = '';
        let preventDefault = false;
        if (!cardNoRegex.test(event.target.value)) {
            let cardNo = event.target.value.replace(/-/g, '');
            if (cardNo.length > 16) {
                cardNo = cardNo.substring(0, 16);
            }

            if (!(/\D/g.test(cardNo))) {
                do {
                    if (cardNo.length > 4) {
                        newCardNo = newCardNo + cardNo.substring(0, 4) + '-';
                        cardNo = cardNo.substring(4, cardNo.length);
                    } else {
                        newCardNo = newCardNo + cardNo;
                        cardNo = '';
                    }
                } while (cardNo.length > 0);
            } else {
                preventDefault = true;
            }
        } else {
            newCardNo = event.target.value;
        }

        if (!preventDefault) {
            setCardNumber(newCardNo);
        } else {
            event.preventDefault();
        }
    };

    const onChangeExpiryDateHandler = (event) => {
        let newExpiryDate = event.target.value.trim();
        if (newExpiryDate) {
            if (newExpiryDate.length === 4 && newExpiryDate.indexOf("-") === -1) {
                newExpiryDate = newExpiryDate.substring(0, 2) + '-' + newExpiryDate.substring(2);
            }
        }

        setExpiryDate(newExpiryDate);
    };

    const processPayment = () => {
        _sendLog(' renewal-detail.js - processPayment() checkpoint ', 'info');
        if (_validateForm(formId)) {
            // remove injected input by SingPass which causes Cybersource payment error
            if (document.querySelector('form[target="payment_iframe"] > input[name="nds-pmd"]')) {
                document.querySelector('form[target="payment_iframe"]').removeChild(document.querySelector('input[name="nds-pmd"]'));
            }

            props.policyPayment.email = emailAddress;
            props.policyPayment.cardHolder = cardHolderName;
            props.policyPayment.expiryDate = expiryDate;
            props.policyPayment.cvv = cvv;
            props.policyPayment.isCustomerPortal = true; //added this flag to prevent checking payment commenced table on previous failed transaction
            props.storePolicyPayment(props.policyPayment);
            props.showSpinner();
            _sendLog(' renewal-detail.js - processPayment(): calling initiatePayment()...', 'info');
            props.initiatePayment(props.policyPayment).then(resp => {
                _sendLog(' renewal-detail.js - processPayment() - initiatePayment() checkpoint ', 'info');

                let cardType = '001';
                let ccExpiryDate = expiryDate.substring(0, 2) + "-20" + expiryDate.substring(3);
                let creditCardNumber = '';

                if (cardNumber) {
                    creditCardNumber = cardNumber.replace(/\s/g, '').replace(/-/g, '');
                    if (new RegExp('^5[1-5]*').test(cardNumber)) {
                        cardType = '002';
                    }
                }

                resp.cardType = cardType;
                resp.cardNumber = creditCardNumber;
                resp.expiryDate = ccExpiryDate;

                setPaymentSignature(resp);
                formRef.current.submit();
            }, (error) => {
                props.hideSpinner();
                props.showError([error]);
                _sendLog(' renewal-detail.js - processPayment() - initiatePayment(): exception:\n\n' + JSON.stringify(error));
            });
        }
    }



    const paymentSuccess = (referenceNumberToken, referenceNumber, auth_code, receiptNumber,
        email, paymentToken, cardExpiryDate, cardNumber, forename, surname, transactionType) => {
        console.log('Payment success, refNumber ' + referenceNumber + ' receiptNumber:' + receiptNumber);
        _sendLog(' renewal-detail.js - paymentSuccess(): referenceNumber = ' + referenceNumber + ', auth_code = ' + auth_code + ', receiptNumber = ' + receiptNumber, 'info');

        if(paymentGatewayIframeRef?.current){
            setShowPaymentGatewayIframeModal(false);
            clearInterval(otpMonitorIntervalId);
            setOtpMonitorIntervalId(null);
            setOtpMonitorActive(false);
            props.showSpinner();
        }

        props.policyPayment.payment = {
            paymentReferenceNumber: referenceNumber,
            paymentReferenceNumberToken: referenceNumberToken,
            auth_code: auth_code,
            receiptNumber: receiptNumber,
            email: email,
            paymentToken: paymentToken,
            cardExpiryDate: cardExpiryDate,
            cardNumber: cardNumber,
            forename: forename,
            surname: surname,
            transactionType: transactionType
        };
        Object.keys(props.policyPayment.payment).forEach(function (key) {
            if(typeof props.policyPayment.payment[key] === 'undefined'){
                delete props.policyPayment.payment[key];
            }
        });

        _sendLog(' renewal-detail.js - paymentSuccess(): calling acceptPayment()...', 'info');
        props.acceptPayment(props.policyPayment).then(resp => {
            console.log(resp);
            _sendLog(' renewal-detail.js - paymentSuccess() - acceptPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
            props.hideSpinner();
            props.storePolicyPayment(resp);
            if (resp.errorMessage) {
                props.showError(['Error in issuing policy']);
                _sendLog(' renewal-detail.js - paymentSuccess() - acceptPayment(): Error in issuing policy!! resp.errorMessage = ' + resp.errorMessage);
            }
            else {
                // prevent reset here if renewal via customer portal.
                // policy object will be required as this is needed on the policy.js page to load properly
                if (!canGoBack()) {
                    props.resetPolicy();
                }
                props.history.push('/renewal-payment-finish');
            }
        }, (error) => {
            props.hideSpinner();
            props.showError(['Error in issuing policy']);
            _sendLog(' renewal-detail.js - paymentSuccess(): acceptPayment(): exception:\n\n' + JSON.stringify(error));
        });
    }

    const paymentError = (message, invalidFields, reasonCode, referenceNumber) => {
        console.log('Payment error message ' + message + ' invalidFields:' + invalidFields + ' reasonCode ' + reasonCode
            + ' referenceNumber ' + referenceNumber);
        _sendLog(' renewal-detail.js - paymentError(): message = ' + message + ', invalidFields = ' + invalidFields
            + ', reasonCode = ' + reasonCode + ', referenceNumber ' + referenceNumber , 'info');

        if(paymentGatewayIframeRef?.current){
            setShowPaymentGatewayIframeModal(false);
            clearInterval(otpMonitorIntervalId);
            setOtpMonitorIntervalId(null);
            setOtpMonitorActive(false);
            setPaymentIframeContentLength(0);
        }

        let errorMessage = message + '&lt;br&gt;' + 'Transaction number: ' + referenceNumber;

        props.hideSpinner();
        props.showError([errorMessage]);
        _sendLog(' renewal-detail.js - paymentError(): errorMessage = ' + errorMessage, 'info');

        props.failPayment(props.policyPayment).then(resp => {
            console.log(resp);
            _sendLog(' renewal-detail.js - paymentError() - failPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
        }, errorMsg => {
            console.error(errorMsg);
            _sendLog(' renewal-detail.js - paymentError() - failPayment(): exception:\n\n' + errorMsg);
        })
    }

    const handleBack = () => {
        if (!props.noLogin) {
            props.history.push('/renewal-notice');
        }
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }

    const getQbelink = () => {
        if (props.policyPayment.country === 'MYS') {
            return "https://www.qbe.com/my/about-qbe";
        } else if (props.policyPayment.country === 'SGP') {
            return "https://www.qbe.com/sg/about-qbe";
        } else if (props.policyPayment.country === 'HKG'){
            return "https://www.qbe.com/hk/en/about-qbe";
        } else {
            return "https://www.qbe.com/about-qbe";
        }
    }

    const monitorPaymentGatewayResponse = () => {
        // For MYS, OTP popup dialog appears when iframe contentWindow.length == 2
        // MYS non-OTP payment will still have some network transactions that at sometime set iframe contentWindow.length == 1
        // HKG and SGP payment transactions always have iframe contentWindow.length == 0
        let paymentIframeDocumentContentCount = document.querySelector('iframe[name=payment_iframe]').contentWindow.length;

        if (paymentIframeDocumentContentCount > 0 && paymentIframeContentLength !== paymentIframeDocumentContentCount) {
            setPaymentIframeContentLength(paymentIframeDocumentContentCount);

            // monitor actual iframe content length for future auditing/debugging in prod
            _sendLog('Customer-Portal-React - renewal payment - monitorPaymentGatewayResponse for policy ' + policyNumber
                + ", payment_iframe content length = " + paymentIframeDocumentContentCount, 'info');
        }

        if (paymentGatewayIframeRef?.current && otpMonitorIntervalId) {
            if (!showPaymentGatewayIframeModal && paymentIframeDocumentContentCount > 1) {
                console.log("Showing OTP dialog...");
                _sendLog('Customer-Portal-React - renewal payment - OTP dialog loaded for policy ' + policyNumber, 'info');
                // stop monitoring
                clearInterval(otpMonitorIntervalId);
                setOtpMonitorActive(false);

                // show otp dialog
                setShowPaymentGatewayIframeModal(true);
                props.hideSpinner();
            }
        }
    }

    const paymentGatewayIframeOnLoad = (e) => {
        _sendLog('Customer-Portal-React - renewal payment - iframe onLoad for policy ' + policyNumber, 'info');

        if (!otpMonitorActive) {
            // iframe contentWindow.length == 0 at this point
            // need a timer to monitor what's being loaded on payment_iframe afterwards
            setOtpMonitorActive(true);
        }
    }

    const getPaymentOptions = () => {
        let options = [];

        if (props.policyPayment.country === 'SGP') {
            options.push({
                'optionLabel': 'Pay by Credit card',
                'optionValue': 'C',
                'optionIcon': CreditCardIcon
            });

            if (!props.policyPayment.disablePayNow && (eligibleForAutoRenewal && autoRenewalStatus === OPTOUT)) {
                options.push({
                    'optionLabel': 'Pay by PayNow',
                    'optionValue': 'P',
                    'optionIcon': PayNowIcon
                });
            }
        } else {
            options.push({
                'optionLabel': 'Pay by Credit card',
                'optionValue': 'C',
                'optionIcon': CreditCardIcon
            });
        }

        return options;
    }

    return (
    <div className="pageContainer renewal-detail-page">
        <div className="container">
            {canGoBack() ?
              <Header/>
              :
              <div className="topRow">
                  <div className="left">
                      <a href={getQbelink()}>
                          <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                      </a>
                  </div>
                  <div className="right">
                      <div className="languageSelect" onClick={() => switchLanguage()}
                           style={{ visibility: (props?.policyPayment.country === 'HKG' ||
                               props?.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                          {(lang === 'en') ?
                            cfg("labels.lang_zh")
                            :
                            cfg("labels.lang_en")
                          }
                      </div>

                  </div>
              </div>
            }
            <TopWrapper>
                {canGoBack() &&
                <BackWrapper onClick={() => handleBack()}>
                    <BackIcon/>
                    Back
                </BackWrapper>
                }
            </TopWrapper>

            <div className="main-page-title">
                <span> {cfg("labels.renewal") + ' ' + productName}</span>
            </div>

            <div className="main-content-container-boxed">
                <div className="main-content-container-box">
                    <div className="main-content-title" style={ props.lang === 'zh' ? {fontWeight: 'bold'} : {} }>
                        { cfg("labels.renewalPayment") }
                    </div>
                    <div className="main-content-box">

                        <div className="highlight highlight-text">
                            {cfg("labels.policyNumber") + ': ' + policyNumber}
                        </div>

                        { props.policyPayment.country === 'SGP' &&
                            <>
                                <br/>
                                <br/>
                                <div className="payment-option-section">
                                    <div className="payment-option-title">{ cfg('labels.paymentOptionTitle') }</div>
                                    <QnectRadioBox
                                        className="payment-option-radio"
                                        field="paymentOption"
                                        formId={formId}
                                        value={paymentOption}
                                        options={ getPaymentOptions() }
                                        onChange={(value) => setPaymentOption(value)}
                                    />
                                </div>
                            </>
                        }

                        <div className="pricing-row">
                            <div className="pricing-block">
                                <div className="highlight-text alignCenter">{ cfg("labels.renewalPremium") }</div>
                                <div className="main-highlight-text alignCenter">
                                    <div>{ _formatPaymentAmount(props.policyPayment.country, totalAmount)}</div>
                                    { props.policyPayment.country === 'SGP' &&
                                        <div style={{fontSize: '15px'}}>
                                            { props.policyPayment.hasGST === 'Y' ? ' w/GST' : ' GST Not Applicable' }
                                        </div>
                                    }
                                </div>

                                { paymentOption === 'C' &&
                                    <div className="credit-cards">
                                        <button className="c-card">
                                            <img src={Visa} width="80" height="46" alt="Visa" />
                                        </button>
                                        <button className="c-card">
                                            <img src={Mastercard} width="80" height="46" alt="MasterCard" />
                                        </button>
                                    </div>
                                }
                                { (paymentOption === 'P' && (eligibleForAutoRenewal && autoRenewalStatus === OPTOUT)) &&
                                    <div className="credit-cards">
                                        <button className="c-card">
                                            <img src={PayNowImg} width="80" height="46" alt="PayNow"/>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        { paymentOption === 'C' &&
                            <div className="form-container">
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField formId={formId} field="emailAddress" value={emailAddress}
                                                        onChange={(event) => setEmailAddress(event.target.value)}/>
                                    </InputGroupStyle>
                                    <div className="form-caption">
                                        <span>{cfg('labels.confirmWording')}</span>
                                    </div>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField formId={formId} field="cardholderName" value={cardHolderName}
                                                        onChange={(event) => {
                                                            setCardHolderName(event.target.value.toUpperCase());
                                                        }}
                                        />
                                    </InputGroupStyle>
                                    <div className="form-caption">
                                        <span>{(props.policyPayment.country === 'HKG' || props.policyPayment.country === 'HGI') ?
                                            cfg('labels.cardHolderNameHelperText') : cfg('labels.cardHolderNameHelperTextSGP')}</span>
                                    </div>
                                </div>
                                <div className="form-field-container">
                                    <InputGroupStyle>
                                        <QnectTextField formId={formId} field="cardNumber" value={cardNumber}
                                                        onChange={(event) => {
                                                            onChangeCardNumberFormatHandler(event)
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (_isNotDecimalChar(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={19}
                                        />
                                    </InputGroupStyle>
                                </div>
                                <div className="two-column">
                                    <div className="form-field-container">
                                        <InputGroupStyle>
                                            <QnectTextField formId={formId} field="expiryDate" value={expiryDate}
                                                            onChange={(event) => onChangeExpiryDateHandler(event)}
                                                            onKeyPress={(event) => {
                                                                if (_isNotDecimalChar(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            maxLength={5}
                                            />
                                        </InputGroupStyle>
                                    </div>
                                    <div className={"form-field-container icon-info-container with-ccv-icon " + (lang === 'en' ? 'eng':'chi')}>
                                        <InputGroupStyle>
                                            <QnectTextField formId={formId} field="cvv" value={cvv}
                                                            onChange={(event) => setCvv(event.target.value)}
                                                            onKeyPress={(event) => {
                                                                if (_isNotDecimalChar(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            maxLength={4}
                                            />
                                        </InputGroupStyle>
                                        <div className={classes.cvv_input}>
                                            <BalloonTip>
                                                {cfg('labels.cvvTip')}
                                            </BalloonTip>
                                        </div>
                                    </div>
                                </div>
                                { autoRenewalStatus === OPTIN &&
                                    <div className="form-field-container">
                                        <div className="form-caption">
                                        <span>{(props.policyPayment.country === 'HKG' || props.policyPayment.country === 'SGP' || props.policyPayment.country === 'MYS') ?
                                            cfg('labels.autoRenewalNotice') : cfg('labels.autoRenewalNotice_zh')}</span>
                                        </div>
                                    </div>
                                }
                                <div className="form-field-container button-container button-size1">
                                    <InputGroupStyle>
                                        <QnectButton field="renewNow" onClick={processPayment}
                                                     disabled={!(policyNumber && emailAddress && cardHolderName && cardNumber && expiryDate && cvv && !errorMessage)}>
                                        </QnectButton>
                                    </InputGroupStyle>
                                </div>
                                {
                                    errorMessage &&
                                    <div className="form-field-container button-container button-size1">
                                        <InputGroupStyle>
                                            <QnectError errorTitle={'Error'} errorMessage={errorMessage}></QnectError>
                                        </InputGroupStyle>
                                    </div>
                                }
                            </div>
                        }

                        { (paymentOption === 'P' && (eligibleForAutoRenewal && autoRenewalStatus === OPTOUT)) &&
                            <div id="pay-by-paynow-section">
                                <div className="form-container">
                                    <div className="form-field-container">
                                        <div className="payment-caption">
                                            <span>{ cfg('labels.scanQrCode')}</span>
                                        </div>
                                        <div className="qr-code">
                                            <QrCode />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div id="pay_confirm_section">
                <form ref={formRef} action={paymentSignature?.paymentUrl} method="post"
                      target="payment_iframe">
                    <input type="hidden" id="access_key" name="access_key"
                           value={paymentSignature?.access_key || ''}/>
                    <input type="hidden" id="profile_id" name="profile_id"
                           value={paymentSignature?.profile_id || ''}/>
                    <input type="hidden" id="transaction_uuid"
                           name="transaction_uuid" value={paymentSignature?.transaction_uuid || ''}/>
                    <input type="hidden" id="signed_field_names" name="signed_field_names"
                           value={paymentSignature?.signed_field_names || ''}/>
                    <input type="hidden" id="unsigned_field_names" name="unsigned_field_names"
                           value={paymentSignature?.unsigned_field_names || ''}/>
                    <input type="hidden" id="signed_date_time" name="signed_date_time"
                           value={paymentSignature?.signed_date_time || ''}/>
                    <input type="hidden" id="locale" name="locale" value={paymentSignature?.locale || ''}/>
                    <input type="hidden" id="transaction_type" name="transaction_type"
                           value={paymentSignature?.transaction_type || ''}/>
                    <input type="hidden" id="reference_number" name="reference_number"
                           value={paymentSignature?.reference_number || ''}/>
                    <input type="hidden" id="amount" name="amount"
                           value={paymentSignature?.amount || ''}/>
                    <input type="hidden" id="currency" name="currency"
                           value={paymentSignature?.currency || ''}/>
                    <input type="hidden" id="payment_method" name="payment_method"
                           value={paymentSignature?.payment_method || ''}/>
                    <input type="hidden" id="bill_to_forename" name="bill_to_forename"
                           value={paymentSignature?.bill_to_forename || ''}/>
                    <input type="hidden" id="bill_to_surname" name="bill_to_surname"
                           value={paymentSignature?.bill_to_surname || ''}/>
                    <input type="hidden" id="bill_to_email" name="bill_to_email"
                           value={paymentSignature?.bill_to_email || ''}/>
                    <input type="hidden" id="bill_to_phone" name="bill_to_phone"
                           value={paymentSignature?.bill_to_phone || ''}/>
                    <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1"
                           value={paymentSignature?.bill_to_address_line1 || ''}/>
                    <input type="hidden" id="bill_to_address_city" name="bill_to_address_city"
                           value={paymentSignature?.bill_to_address_city || ''}/>
                    <input type="hidden" id="bill_to_address_state"
                           name="bill_to_address_state"
                           value={paymentSignature?.bill_to_address_state || ''}/>
                    <input type="hidden" id="bill_to_address_country" name="bill_to_address_country"
                           value={paymentSignature?.bill_to_address_country || ''}/>
                    <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code"
                           value={paymentSignature?.bill_to_address_postal_code || ''}/>
                    <input type="hidden" id="override_custom_receipt_page" name="override_custom_receipt_page"
                           value={paymentSignature?.override_custom_receipt_page || ''}/>
                    <input type="hidden" id="signature" name="signature"
                           value={paymentSignature?.paymentSignatureKey || ''}/>
                    <input type="hidden" id="card_type" name="card_type"
                           value={paymentSignature?.cardType || ''}/>
                    <input type="hidden" id="card_number" name="card_number"
                           value={paymentSignature?.cardNumber || ''}/>
                    <input type="hidden" id="card_expiry_date" name="card_expiry_date"
                           value={paymentSignature?.expiryDate || ''}/>
                    <input type="hidden" id="card_cvn" name="card_cvn" value={cvv || ''}/>
                    {
                        (paymentSignature?.payment_token) &&
                        <input type="hidden" id="payment_token" name="payment_token"
                               value={paymentSignature?.payment_token || ''}/>
                    }
                </form>

                <Overlay show={showPaymentGatewayIframeModal}>
                    <iframe className={showPaymentGatewayIframeModal ? "PaymentGatewayIframeVisible" : "PaymentGatewayIframeHidden"} name="payment_iframe" src="" frameBorder="0" title="Payment" onLoad={paymentGatewayIframeOnLoad} ref={paymentGatewayIframeRef}></iframe>
                </Overlay>
            </div>
        </div>

    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    storePolicyPayment: _storePolicyPayment,
    resetPolicy: _resetPolicy,
    initiatePayment: _renewInitiatePayment,
    failPayment: _issueFailed,
    acceptPayment: _issueAndReceiptPolicy,
    generateQrCode: _adhocGenerateQrCode
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalDetail));

