import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "../dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../dialog-title";
import QnectButton from "../../qnect-ui/qnect-button";
import ButtonWrapper from "../button-wrapper";
import { smDown } from "../../../ui/common/breakpoints";

const StyledMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #0a1f44;
  font-family: Inter;
  width: 470px;
  ${smDown`
    font-size: 16px;
    line-height: 20px;
    width: auto;
  `}
`;

const StyledQnectButton = styled(QnectButton)`
  width: 180px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  font-family: Inter;
  margin: 0;
`;

const StyledCancelQnectButton = styled(StyledQnectButton)`
  box-shadow: none;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  justify-content: space-between;
  width: 390px;
  margin: 0 auto;
  ${smDown`
    width: 100%;
  `}
`;

const StyledDialog = withStyles((theme) => ({
  paper: {
    padding: "14px 76px 40px",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "14px 28px 32px",
    },
  },
  paperWidthSm: {
    boxSizing: "border-box",
    maxWidth: "none",
  },
}))(Dialog);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: "44px 52.5px 0",
    fontFamily: "Inter",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0 0",
    },
  },
}))(DialogActions);

export default function LearnMoreDialog({ open, onClose, onConfirm, cfg, lang }) {

  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle title={cfg('labels.fileAClaim.title')} closable={false} lang={lang}/>
        <DialogContent>
          <StyledMessage>
            {cfg('labels.fileAClaim.message')}
          </StyledMessage>
        </DialogContent>
        <StyledDialogActions>
          <StyledButtonWrapper>
            <StyledCancelQnectButton field="cancel" onClick={() => onClose()} />
            <StyledQnectButton field="confirm" onClick={() => onConfirm()}></StyledQnectButton>
          </StyledButtonWrapper>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
}
