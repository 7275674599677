import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import useConfig from "../qnect-ui/qnect-config";
import {_getLang} from "../../store/system/service";
import {connect} from "react-redux";
import BalloonTip from "../ui/balloon-tip/balloon-tip";
import styled from "styled-components";
import QnectButton from "../qnect-ui/qnect-button";
import ButtonWrapper from "../custom/button-wrapper";
import {_getUser} from "../../store/user/service";

const useStyles = makeStyles(theme => ({
    row: {
        display: "inline-flex",
        alignItems: "left",
        color: "#0a1f44",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "20px",
        marginBottom: "20px",
        "& > span:first-child": {
            [theme.breakpoints.down("sm")]: {
                width: "120px"
            },
            [theme.breakpoints.up("sm")]: {
                width: "240px"
            }
        },
        "& > span.title, &.title": {
            color: "#00205B",
            fontFamily: "Inter-SemiBold"
        },
        "&.address": {
            marginTop: "24px"
        }
    },
    msg1: {
        color: "#8A94A6",
        fontFamily: "Inter-Regular",
        textAlign: "center",
        marginBottom: "45px",
        marginTop: "37px"
    },
    msgContainer: {
        borderTop: "1px solid #E1E4E8",
        marginTop: "20px"
    }
}));

const StyledBalloonTip = styled(BalloonTip)`
    & > div.imageDiv > img {
        width: 20px;
        height: 20px;
    }
    
    & > div.ToolTipDiv > div.contentDiv {
        top: 3px;
        left: 196px;
        width: 334px;
    }
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
    text-align: center;
    margin-bottom: 20px;
    & > button.Button {
        width: 280px;
    }
`;

const ProfileBaseUpdateForm = (props) => {
    const classes = useStyles();
    const cfg = useConfig(props.lang);

    return (
        <>
            <div className={classes.row}>
                <span className={'title'}>{cfg('labels.myProfile.loginAndContact')}</span>
            </div>
            <div className={classes.row}>
                <span>{cfg('labels.myProfile.emailAddress')}</span>
                <span>{props.user.email}</span>
            </div>
            <div className={classes.row}>
                <span>{cfg('labels.myProfile.mobileNumber')}</span>
                <span>{'+' + props.user.countryCode + props.user.phoneNumber}</span>
            </div>
            <div className={classes.row + ' address title'}>
                <span>
                    {cfg('labels.myProfile.correspondenceAddress')}
                    <StyledBalloonTip>
                        {cfg('labels.myProfile.correspondenceAddressTooltip')}
                    </StyledBalloonTip>
                </span>
                <span>{'-'}</span>
            </div>
            <div className={classes.msgContainer}>
                <div className={classes.msg1}>
                    {cfg('labels.myProfile.reminder')}
                </div>
                <StyledButtonWrapper>
                    <QnectButton onClick={props.toggleUpdateForm}>{cfg('labels.myProfile.updateProfileButton')}</QnectButton>
                </StyledButtonWrapper>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        user: _getUser(state)
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBaseUpdateForm);


