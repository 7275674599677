import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import styled, {css} from "styled-components";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import PolicyHeader from "./policy-header";
import PolicyDetail from "./policy-detail";
import PolicyDocuments from "./policy-documents";
import PolicyClaims from "./policy-claims";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import {ReactComponent as Mail} from "../../assets/policyDetails/Mail_sharp.svg";
import {ReactComponent as Download} from "../../assets/policyDetails/download_sharp.svg";
import {ReactComponent as ArrowRight} from "../../assets/registration/arrow_forward_sharp.svg";
import AgentContactDialog from "../custom/policy-detail/agent-contact-dialog";
import {
    _clearPolicyToken, _clearState, _getCountry, _getCpToken,
    _getLang,
    _getPolicyToken,
    _hideSpinner, _isLocalhost,
    _isNoLogin,
    _setDocumentToken,
    _showSpinner
} from "../../store/system/service";
import {
    _getPolicy,
    _loadDocument,
    _loadPolicyClaims,
    _loadPolicyDetail,
    _loadPolicyDocuments,
    _resetPolicy,
    _sendDocumentEmail,
    _storePolicy
} from "../../store/policy/service";
import {_getUser, _getUserDetailById, _getUserDetailsFromToken} from "../../store/user/service";
import useConfig from "../qnect-ui/qnect-config";
import moment from "moment";
import {getDocName} from "../../utils/utility";
import Header from "../custom/header";
import UpdateEmailMobileDialog from "../custom/policy-detail/update-email-mobile-dialog";
import * as TagManager from "react-gtm-module";
import AreYouStillThereDialog from "../custom/portfolio/are-you-still-there-dialog";

const BackIcon = styled(Back)`
  margin-right: 8px;
`;
const MailIcon = styled(Mail)`
  margin-right: 4px;
`;

const DownloadIcon = styled(Download)`
  margin-right: 4px;
`;

const Container = styled.div`
  background: #f6f8fa;
`;

const useStyles = makeStyles(theme => ({
    box1: {
        width: "100%",
        background: "#fff",
        [theme.breakpoints.down("sm")]: {
            height: "230px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: "230px"
        },
        [theme.breakpoints.up("lg")]: {
            height: "289px"
        }
    },
    header: {
        [theme.breakpoints.between("sm", "md")]: {
            width: "100%"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            width: "100%",
            maxWidth: "1110px"
        }
    },
    topWrapper: {
        position: "relative",
        height: "100%",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.between("sm", "md")]: {
            margin: "24px auto 0 ",
            width: "100%",
            maxWidth: "720px"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "40px auto 0",
            width: "100%",
            maxWidth: "1110px"
        }
    },
    policyHeader: {
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "calc(100% - 32px)",
            margin: "20px auto 0"
        }
    },
    policyHeaderNoLogin: {
        marginTop: "40px"
    },
    agentContentRow: {
        margin: "100px 16px 0",
        padding: "0 20px",
        height: "64px",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)",
        color: "#003DA5",
        fontFamily: "Inter-Medium",
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    arrowRight: {
        width: "20px"
    },
    box2: {
        [theme.breakpoints.down("sm")]: {
            margin: "30px auto 0",
            paddingBottom: "30px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            margin: "264px auto 0",
            paddingBottom: "40px",
            width: "100%",
            maxWidth: "720px"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "304px auto 0",
            paddingBottom: "64px",
            width: "100%",
            maxWidth: "1110px"
        }
    },
    box2_nologin: {
        [theme.breakpoints.down("sm")]: {
            margin: "30px auto 0"
        },
        [theme.breakpoints.between("sm", "md")]: {
            margin: "64px auto 0",
            width: "100%",
            maxWidth: "720px"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "121px auto 0",
            width: "100%",
            maxWidth: "1110px"
        }
    },
    tab: {
        "&.MuiTabs-root": {
            minHeight: "30px"
        },
        "& .MuiTabs-indicator": {
            height: "2px",
            borderRadius: 1,
            backgroundColor: "#003DA5"
        },
        "& .MuiTabs-flexContainer": {
            [theme.breakpoints.down("sm")]: {
                justifyContent: "center"
            }
        },
        "& .MuiTab-root": {
            paddingLeft: 0,
            paddingRight: 0,
            minHeight: "30px",
            textTransform: "none",
            fontFamily: "Stag",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "center",
            [theme.breakpoints.down("sm")]: {
                "&:last-child": {
                    marginLeft: "30px"
                },
                fontSize: 16,
                letterSpacing: "0.89px"
            },
            [theme.breakpoints.up("sm")]: {
                "&:last-child": {
                    marginLeft: "20px"
                },
                minWidth: "70px",
                fontSize: 18,
                letterSpacing: "1px"
            },
            [theme.breakpoints.up("lg")]: {
                marginLeft: "20px"
            }
        },
        "& .MuiTab-textColorPrimary": {
            color: "#C1C9EA"
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: "#003DA5"
        }
    },
    tabPanelContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px 16px 40px"
        }
    },
    [theme.breakpoints.between("sm", "md")]: {
        tabPanelContent: props => ({
            paddingTop: props.noLogin ? "0px" : "24px",
            paddingBottom: "80px"
        })
    },
    [theme.breakpoints.up("lg")]: {
        tabPanelContent: props => ({
            paddingTop: props.noLogin ? "0px" : "44px",
            paddingBottom: "120px"
        })
    },
    sectionTitle: {
        fontFamily: "Stag",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        color: "#0a1f44",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "20px",
            marginLeft: "10px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginBottom: "24px"
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "30px",
            marginLeft: "20px"
        }
    },
    sectionWrapper: {
        [theme.breakpoints.only('xs')]: {
            margin: "30px 16px 0"
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "30px"
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "64px"
        }
    },
    documentTitle: {
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            justifyContent: "space-between"
        }
    },
    documentAction: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "20px",
            marginLeft: "10px"
        },
        [theme.breakpoints.up("lg")]: {}
    },
    mailIconWrapper: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        color: "#8a94a6",
        fontFamily: "Inter",
        fontWeight: "500",
        letterSpacing: "0",

        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "21px",
            "&:nth-child(2)": {
                marginLeft: "16px"
            }
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "16px",
            lineHeight: "20px",
            "&:nth-child(2)": {
                marginLeft: "30px"
            }
        }
    },
    disclaimer: {
        whiteSpace: "pre-wrap",
        fontSize: "16px",
        fontFamily: "Inter-Regular",
        [theme.breakpoints.up("md")]: {
            marginTop: "30px",
            marginLeft: "20px"
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px",
            marginLeft: "6px"
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: "20px",
            marginLeft: "0"
        }
    }
}));

const Box1 = styled.div`
  height: ${props => (props.noLogin ? "289px" : "432px")};
`;

const BackWrapper = styled.div`
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const Title = styled.div`
  margin: 40px 0px 56px 20px;
  font-size: 40px;
  line-height: 48px;
  font-family: Stag;
  font-weight: 500;
  letter-spacing: 1px;
  color: #003da5;
  ${({lang}) => {
    if (lang === 'zh') {
        return css`
        font-weight: bold;
      `
    } else {
        return null;
    }
}}
`;

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
    };
}

const Policy = (props) => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const classes = useStyles(props);

    const [agentOpen, setAgentOpen] = useState(false);

    const [openAreYouStillThereDialogState, setOpenAreYouStillThereDialogState] = React.useState(false);
    const SESSION_TIMEOUT = 60 * 15;
    const [sessionTimer, setSessionTimer] = useState(SESSION_TIMEOUT);
    const [sessionTimerTimeout, setSessionTimerTimeout] = useState(null);

    useEffect(() => {
        if (props.cpToken && !props.policy?.policyNumber) {
            console.log('loadPolicyDetail started');
            props.showSpinner();
            let fromDate = moment(props.policy?.effectiveDate).format('DD/MM/YYYY');
            props.loadPolicyDetail(fromDate).then((policy) => {
                console.log('loadPolicyDetail complete');
                policy.premiumAmount = props.policy.origAmount;
                policy.renewalFlag = props.policy.renewalFlag;
                props.storePolicy(policy);
                props.hideSpinner();
            }, (error) => {
                props.hideSpinner();
                console.error('loadPolicyDetail has failed', error);
            })
        }

    }, []);

    const setDisplayStyle2 = () => {
        if (props.country === 'SGP') {
            return {display: 'none'}
        }
        return {};
    }


    const handleBackToPortfolioPage = () => {
        props.resetPolicy();
        props.clearPolicyToken();
        history.push('/portfolio');
        TagManager.dataLayer({
            dataLayer: {
                event: 'Icon-Click',
                eventCategory: 'Back-Arrow'
            }
        })
    }

    const handleLogout = () => {
        props.clearState();
        props.history.push('/login');
    }

    const resetSessionTimer = () => {
        clearTimeout(sessionTimerTimeout);
        setSessionTimer(SESSION_TIMEOUT);
    }

    useEffect(() => {
        if(props.country == 'MYS') {
            clearTimeout(sessionTimerTimeout);
            if (sessionTimer > 0) {
                let timeoutId = setTimeout(() => {
                    setSessionTimer(sessionTimer - 1);
                }, 1000);
                setSessionTimerTimeout(timeoutId);
            } else {
                setOpenAreYouStillThereDialogState(true);
            }
        }
    }, [sessionTimer]);

    useEffect(() => {
        document.addEventListener('mousemove', () => {
            resetSessionTimer();
        });
        document.addEventListener('keypress', () => {
            resetSessionTimer();
        });

    }, []);

    return (
        <Container>
            <Box1 id="box1" className={classes.box1} noLogin={props.noLogin}>
                <div className={classes.header}>
                    <Header pathname={props.history.location.pathname}/>
                </div>
                <div className={classes.topWrapper} id="top-wrapper">
                    <Hidden mdDown>
                        {!props.noLogin && props.cpToken &&
                        <BackWrapper onClick={() => {
                            handleBackToPortfolioPage();
                        }}>
                            <BackIcon/>
                            {cfg('policy.back')}
                        </BackWrapper>
                        }
                        <Title lang={props.lang}>{cfg('policy.policyDetails')}</Title>
                    </Hidden>

                    {!props.noLogin ? (
                        <PolicyHeader
                            className={classes.policyHeader}
                            policy={props.policy}
                            noLogin={props.noLogin}
                            cfg={cfg}
                        />
                    ) : (
                        <PolicyHeader
                            className={[
                                classes.policyHeader,
                                classes.policyHeaderNoLogin
                            ].join(" ")}
                            policy={props.policy}
                            noLogin={props.noLogin}
                            cfg={cfg}
                        />
                    )}
                </div>
            </Box1>

            {!props.noLogin &&
            <Hidden smUp>
                <div
                    className={classes.agentContentRow}
                    onClick={() => setAgentOpen(true)}
                    style={setDisplayStyle2()}
                >
                    {cfg('policy.agentContact')}
                    <ArrowRight className={classes.arrowRight}/>
                </div>
            </Hidden>
            }

            <div className={classes.box2}>
                <div className={classes.sectionWrapper + " policy-details-block"}>
                    <div className={classes.sectionTitle} style={props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                        {cfg('policy.policyDetails')}
                    </div>
                    <PolicyDetail policy={props.policy} noLogin={props.noLogin} cfg={cfg}/>
                </div>

                <div className={classes.sectionWrapper}>
                    <div className={classes.documentTitle}>
                        <div className={classes.sectionTitle} style={props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                            {cfg("policy.documents")}
                        </div>
                    </div>
                    <PolicyDocuments policy={props.policy} cfg={cfg} policyEmail={props.policy.email}/>
                </div>

                {
                    !_isLocalhost &&
                    <div className={classes.sectionWrapper}>
                        <div className={classes.sectionTitle} style={props.lang === 'zh' ? {fontWeight: 'bold'} : {}}>
                            {cfg("policy.claims")}
                        </div>
                        <PolicyClaims policy={props.policy} cfg={cfg}/>
                    </div>
                }

                <AgentContactDialog
                    open={agentOpen}
                    onClose={() => setAgentOpen(false)}
                    cfg={cfg}
                />


            </div>
            <AreYouStillThereDialog
                open={openAreYouStillThereDialogState}
                onLogout={() => {
                    handleLogout();
                }}
                onClose={() => {
                    setOpenAreYouStillThereDialogState(false);
                }}
                cfg={cfg}
                lang={props.lang}
                country={props.country}
            />
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        noLogin: _isNoLogin(state),
        policy: _getPolicy(state),
        lang: _getLang(state),
        user: _getUser(state),
        cpToken: _getCpToken(state),
        policyToken: _getPolicyToken(state)
    }
}

const mapDispatchToProps = {
    storePolicy: _storePolicy,
    loadPolicyDetail: _loadPolicyDetail,
    clearPolicyToken: _clearPolicyToken,
    clearState: _clearState,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    resetPolicy: _resetPolicy,
    getUserDetailById: _getUserDetailById,
    getUserDetailsFromToken: _getUserDetailsFromToken,
    loadPolicyDocuments: _loadPolicyDocuments,
    loadPolicyClaims: _loadPolicyClaims,
    setDocumentToken: _setDocumentToken,
    loadDocument: _loadDocument,
    sendDocumentEmail: _sendDocumentEmail,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Policy));
